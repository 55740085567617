<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card card-psh border">
          <div class="bg-light-primary rounded-top p-half">
            <div class="psh-header mb-0 d-flex align-items-center">
              <div class="mr-1 px-1 border-0">
                <p-icon name="feather-check-square" size="40px" />
              </div>
              <div class="d-flex flex-column pt-half">
                <h4 class="card-title m-0">
                  Verify Batch
                </h4>
                <p class="d-none d-sm-block">
                  Verify the item consistency in a batch.
                </p>
              </div>
              <div class="px-2 text-success text-center ml-5">
                Count
                <h3 class="text-success">
                  {{ counter }}
                </h3>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex mb-1">
              <input id="code-input"
                v-model="code" type="text"
                class="form-control col-sm-5 mr-1" placeholder="Code"
                aria-describedby="button-add-action"
                style="max-width:300px"
                @keydown.enter="onSubmit($event)"
                @keydown.esc="onCancel($event)"
                >
              <dx-util-button text="Reset" class="text-black" type="warning" @click="reset()" />
            </div>
            <dx-data-grid
              :data-source="codes"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :row-alternation-enabled="true"
            >
            <dx-column data-field="counter" :width="100" />
            <dx-column data-field="code" cell-template="codeTemplate" />
            <template #codeTemplate="{data}">
              <span :class="`badge-${resolveConditionVariant(data.value)}`"
                class="d-block badge badge-pill"
              >
              {{ data.value }}
              </span>
            </template>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    code: '',
    codes: [],
    firstCode: 0,
    counter: 0,
  }),
  methods: {
    resolveConditionVariant(code) {
      if (this.firstCode === 0 || code === this.firstCode) return 'success'
      return 'danger'
    },
    onSubmit() {
      if (this.code === '') {
        return
      }
      if (this.firstCode === 0) {
        this.firstCode = this.code
      }
      this.counter += 1
      this.codes.unshift({ counter: this.counter, code: this.code })
      this.code = ''
    },
    onCancel() {
      this.code = ''
    },

    reset() {
      this.firstCode = 0
      this.counter = 0
      this.codes.splice(0, this.codes.length)
    },
  },
}
</script>
<style lang="scss">
</style>
